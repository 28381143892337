






































































































































section {
  main {
    width: 100%;
    height: 100%;
    background-color: #F8F8F8;
    padding-top: 70px;
    padding-bottom: 140px;
    .tab-list-wrapper {
      width: 1200px;
      margin: 0 auto;
    }

    .tab-list {
      margin: 80px 0;
      margin-right: -30px;

      > ul {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        > li {
          width: 380px;
          margin-right: 30px;
          margin-bottom: 20px;
        }
      }
    }

    .pagination {
      > .container {
        justify-content: center;
      }
    }
  }
}
@media (max-width: 767px) {
  section {
    background: rgb(248,248,248);
    main {
      width: 100%;
      .tab-list-wrapper {
        width: 95%;
      }
      .tab-list{
        width: 100%;
        padding: 0;
        > ul{
          display: block;
          > li {
            width: 100%;
            margin: 20px 0;
          }
        }
      }
    }
  }
}
